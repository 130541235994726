<template>
  <div>
    <v-app-bar id="menu" app absolute elevation="0">
      <v-app-bar-nav-icon
        color="#3E4095"
        @click.stop="drawer = !drawer"
        class="d-sm-flex d-md-none"
      />
      <v-container class="py-0 text-right text-md-center">
        <div class="align-end justify-md-center ">
          <img
            id="logo-menu"
            class="mr-5 d-none d-md-inline"
            src="images/logo_huwe.png"
          />
          <div class="d-none d-md-inline">
            <v-btn
              v-for="(item, i) in links"
              :key="i"
              :dark="currentRoutePath === item.to"
              :text="currentRoutePath === item.to ? false : true"
              :color="currentRoutePath === item.to ? '#3E4095' : ''"
              tile
              :to="item.to"
              elevation="0"
              class="menu-button"
            >
              {{ item.text }}
            </v-btn>
          </div>
          <v-btn
            href="https://wa.me/5551997311319"
            target="_blank"
            depressed
            dark
            small
            color="green"
            class="mb-2 mb-md-1 ml-2"
          >
            <v-icon left color="white" v-text="'mdi-whatsapp'" />
            <b>(51) 99731-1319</b>
          </v-btn>
        </div>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list-item>
        <v-list-item-content class="">
          <v-list-item-title class="justify-center">
            <v-img width="60%" src="images/logo_huwe.png" class="mx-auto" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list nav dense>
        <v-list-item-group active-class="">
          <v-list-item
            v-for="(item, i) in links"
            :key="i"
            @click="() => $router.push(item.to)"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    links: [
      {
        text: 'Home',
        to: '/',
      },
      {
        text: 'Quem Somos',
        to: '/quem-somos',
      },
      {
        text: 'Serviços',
        to: '/servicos',
      },
      {
        text: 'Produtos',
        to: '/produtos',
      },
      {
        text: 'Obituário',
        to: '/obituario',
      },
    ],
  }),

  computed: {
    currentRoutePath() {
      return this.$route.path
    },
  },

  watch: {},

  mounted() {},

  methods: {},
}
</script>

<style lang="sass">
#menu
  background: red
  background: linear-gradient(180deg, rgba(213,213,213,1) 0%, rgba(255,255,255,1) 100%)
  border-bottom: 2px solid $huwe-color

  .v-toolbar__content
    align-items: end
    padding-bottom: 0

  #logo-menu
    width: 140px
    position: relative
    bottom: -10px
</style>
